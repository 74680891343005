import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import HomePage from "@pages/home";
import NotFoundPage from "@pages/404";
import LoginPage from "@/pages/login";
import ErrorBoundary from "@/components/error-boundary";
import AuthLayout from "./utils/authLayout";

const MerchantPage = lazy(() => import("@/pages/merchant"));
const MerchantsPage = lazy(() => import("@/pages/merchant-list"));
const MerchantsInfoPage = lazy(
  () => import("@/pages/merchant-list/merchants-info-page")
);
const GoodsPage = lazy(() => import("@/pages/goods"));
const GoodsInfoPage = lazy(() => import("@/pages/goods/goods-info-page"));
const StorePage = lazy(() => import("@/pages/store"));
const StoreInfoPage = lazy(() => import("@/pages/store/store-info-page"));
const TemplatePage = lazy(() => import("@/pages/template"));
const TemplateInfoPage = lazy(
  () => import("@/pages/template/template-info-page")
);
const TemplateSettingPage = lazy(
  () => import("@/pages/template/template-setting")
);
const MarketingPage = lazy(() => import("@/pages/marketing"));
const MarketingInfoPage = lazy(
  () => import("@/pages/marketing/marketing-info-page")
);
const RolePage = lazy(() => import("@/pages/role"));
const RoleInfoPage = lazy(() => import("@/pages/role/role-info-page"));
const OrderPage = lazy(() => import("@/pages/order"));
const OrderDetailPage = lazy(
  () => import("@/pages/order-detail/order-info-page")
);
const StaffPage = lazy(() => import("@/pages/staff"));
const StaffInfoPage = lazy(() => import("@/pages/staff/staff-info-page"));
const DevicePage = lazy(() => import("@/pages/device"));
const DeviceInfoPage = lazy(() => import("@/pages/device/device-info-page"));
const DeviceVersionPage = lazy(() => import("@/pages/device-version"));
const DeviceVersionInfoPage = lazy(
  () => import("@/pages/device-version/device-info-page")
);
const DecorationPage = lazy(() => import("@/pages/decoration"));
const DecorationInfoPage = lazy(
  () => import("@/pages/decoration/decoration-info-page")
);
const SystemConfigPage = lazy(() => import("@/pages/systemConfig"));
const PurikuraFramePage = lazy(() => import("@/pages/purikura-frame"));
const PurikuraFrameInfoPage = lazy(
  () => import("@/pages/purikura-frame/purikura-frame-info")
);
const ThemePage = lazy(() => import("@/pages/themeManage"));
const ThemeInfoPage = lazy(() => import("@/pages/themeManage/theme-info-page"));
const EquityPage = lazy(() => import("@/pages/equity"));
const CouponPage = lazy(() => import("@/pages/coupon"));
const GroupPurchasePage = lazy(() => import("@/pages/group-purchase-coupon"));
const CouponInfoPage = lazy(() => import("@/pages/coupon/coupon-info-page"));
const PayGiftsInfoPage = lazy(
  () => import("@/pages/payGifts/payGifts-info-page")
);
const PrintPage = lazy(() => import("@/pages/print"));
const PrinterDetailPage = lazy(
  () => import("@/pages/order-detail/print-info-page")
);
const WorkDisplaySortPage = lazy(
  () => import("@/pages/systemConfig/workDisplaySort")
);
const MerchantAuthenticationPage = lazy(
  () => import("@/pages/merchant-authentication")
);

const routeList = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/login",
    element: (
      <ErrorBoundary>
        <LoginPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/home",
    element: (
      <ErrorBoundary>
        <AuthLayout>
          <HomePage />
        </AuthLayout>
      </ErrorBoundary>
    ),
    children: [
      {
        path: "merchant",
        element: <MerchantPage />,
      },
      {
        path: "merchant/authentication/:id",
        element: <MerchantAuthenticationPage />,
      },
      {
        path: "merchants",
        element: <MerchantsPage />,
      },
      {
        path: "merchants/create",
        element: <MerchantsInfoPage />,
      },
      {
        path: "merchants/edit/:id",
        element: <MerchantsInfoPage />,
      },
      {
        path: "merchants/authentication/:id",
        element: <MerchantAuthenticationPage />,
      },
      {
        path: "goods",
        element: <GoodsPage />,
      },
      {
        path: "goods/goodsInfoPage",
        element: <GoodsInfoPage />,
      },
      {
        path: "goods/goodsInfoPage/:id",
        element: <GoodsInfoPage />,
      },
      {
        path: "goods/goodsInfoPage/:id/:detail",
        element: <GoodsInfoPage />,
      },
      {
        path: "store",
        element: <StorePage />,
      },
      {
        path: "store/create",
        element: <StoreInfoPage />,
      },
      {
        path: "store/edit/:id",
        element: <StoreInfoPage />,
      },
      {
        path: "template",
        element: <TemplatePage />,
      },
      {
        path: "template/templateInfoPage",
        element: <TemplateInfoPage />,
      },
      {
        path: "template/templateInfoPage/:id",
        element: <TemplateInfoPage />,
      },
      {
        path: "template/templateSettingPage/:id/:type",
        element: <TemplateSettingPage />,
      },
      {
        path: "marketing",
        element: <MarketingPage />,
      },
      {
        path: "marketing/create/:activityType",
        element: <MarketingInfoPage />,
      },
      {
        path: "marketing/edit/:activityType/:id",
        element: <MarketingInfoPage />,
      },
      {
        path: "role",
        element: <RolePage />,
      },
      {
        path: "role/:type/:action/:id?",
        element: <RoleInfoPage />,
      },
      {
        path: "order",
        element: <OrderPage />,
      },
      {
        path: "order/detail/:id",
        element: <OrderDetailPage />,
      },
      {
        path: "staff",
        element: <StaffPage />,
      },
      {
        path: "staff/:type/:action/:id?",
        element: <StaffInfoPage />,
      },
      {
        path: "systemConfig",
        element: <SystemConfigPage />,
      },
      {
        path: "device",
        element: <DevicePage />,
      },
      {
        path: "device/edit/:id",
        element: <DeviceInfoPage />,
      },
      {
        path: "deviceVersion",
        element: <DeviceVersionPage />,
      },
      {
        path: "deviceVersion/create",
        element: <DeviceVersionInfoPage />,
      },
      {
        path: "deviceVersion/edit/:id",
        element: <DeviceVersionInfoPage />,
      },
      {
        path: "purikuraFrame",
        element: <PurikuraFramePage />,
      },
      {
        path: "purikuraFrame/create",
        element: <PurikuraFrameInfoPage />,
      },
      {
        path: "purikuraFrame/edit/:id",
        element: <PurikuraFrameInfoPage />,
      },
      {
        path: "themeManage",
        element: <ThemePage />,
      },
      {
        path: "themeManage/create",
        element: <ThemeInfoPage />,
      },
      {
        path: "themeManage/edit/:id",
        element: <ThemeInfoPage />,
      },
      {
        path: "decoration",
        element: <DecorationPage />,
      },
      {
        path: "decoration/create",
        element: <DecorationInfoPage />,
      },
      {
        path: "decoration/edit/:id",
        element: <DecorationInfoPage />,
      },
      {
        path: "equity",
        element: <EquityPage />,
      },
      {
        path: "coupon",
        element: <CouponPage />,
      },
      {
        path: "coupon/create",
        element: <CouponInfoPage />,
      },
      {
        path: "coupon/edit/:id",
        element: <CouponInfoPage />,
      },
      {
        path: "coupon/:detail/:id",
        element: <CouponInfoPage />,
      },
      {
        path: "groupPurchase",
        element: <GroupPurchasePage />,
      },
      {
        path: "equity",
        element: <EquityPage />,
      },
      {
        path: "coupon",
        element: <CouponPage />,
      },
      {
        path: "coupon/create",
        element: <CouponInfoPage />,
      },
      {
        path: "coupon/edit/:id",
        element: <CouponInfoPage />,
      },
      {
        path: "coupon/:detail/:id",
        element: <CouponInfoPage />,
      },
      {
        path: "payGifts/edit/",
        element: <PayGiftsInfoPage />,
      },
      {
        path: "print",
        element: <PrintPage />,
      },
      {
        path: "print/detail/:id",
        element: <PrinterDetailPage />,
      },
      {
        path: "workSort",
        element: <WorkDisplaySortPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default routeList;
