export enum CouponTypeEnum {
  CASH_COUPON = 1, //现金券
  EXCHANGE_COUPON = 2, //兑换券
  DISCOUNT_COUPON = 3, //折扣券
}

export enum GroupPurchaseCouponStatusEnum {
  WAIT_TO_USE = 0,
  VALID = 1,
  INVALID = -1,
}

export const GROUP_PURCHASE_COUPON_STATUS = [
  {
    label: "已使用",
    value: GroupPurchaseCouponStatusEnum.VALID,
  },
  {
    label: "待使用",
    value: GroupPurchaseCouponStatusEnum.WAIT_TO_USE,
  },
  {
    label: "已过期",
    value: GroupPurchaseCouponStatusEnum.INVALID,
  },
];

export enum CouponStatusEnum {
  ENABLE = 1,
  DISABLE = -1,
}

export enum CouponTimeEnum {
  TODAY = 1, //当天
  EFFECTIVE_DAY = 2, //指定天数
}

export enum OrderSourceEnum {
  SHOOT_DEVICE = 1, //拍照机
  APPLET = 2, //小程序
}

export enum ProvideChannelEnum {
  BOJUE_SHOOT = 1, //铂爵智拍
  TIKTOK = 2, //抖音
  MEITUAN = 3, //美团
}

export const PROVAIDER_CHANNEL_OPTIONS = [
  {
    label: "铂爵智拍",
    value: ProvideChannelEnum.BOJUE_SHOOT,
  },
  {
    label: "抖音",
    value: ProvideChannelEnum.TIKTOK,
  },
  {
    label: "美团",
    value: ProvideChannelEnum.MEITUAN,
  },
];

export enum ApplyMerchantEnum {
  ALL, //全部门店
  DIRECT_SALE, //直营
  VERIFY, //核销门店
  APPOINT, //指定门店
}
