/** 全部业务状态 */
export enum BusinessStatusEnum {
  UNPAID = 0, // 待支付
  WAIT_COMPOSE = 1, // 等待制作 后端用于处理历史数据
  WAIT_PHOTO_CAPTURE = 2, // 待拍照上传
  WAIT_PHOTO_RETOUCH = 3, // 待修图
  WAIT_FILL_INFO = 4, // 待填写信息
  WAIT_SELECT_TEMPLATE = 6, // 待选模板
  WAIT_UPLOAD_PHOTO = 7, // 待上传照片
  MAKING = 8, // 制作中
  WAIT_SELECT = 10, // 待选片
  FINISH_SELECT = 20, // 选片已完成 后端用于处理历史数据
  WAIT_PRINT = 30, // 等待打印
  COMPELELTE_PRINT = 32, // 打印完成 后端用于处理历史数据
  FINISH = 40, // 已完成
}

/** 订单状态 */
export enum OrderStatusEnum {
  WAIT_PAY = 0, //待付款
  IN_PROGRESS = 1, //服务中
  DONE = 2, //已完成
  CANCEL = 3, //已取消
  REFUND = 4, //已退款
}

/** 成交渠道 */
export enum TransactionChannelEnum {
  SHOOT_DEVICE = 1, // 智拍机
  MINI_PROGRAM = 2, // 小程序+
  MARKETING_ACTIVITY = 3, // 营销活动
  MEMBER_EQUITY = 4, // 会员权益
  TIK_TOK = 5, // 抖音团购
  KEE_TA = 6, // 美团团购
  SCAN_PRINT = 7, // 扫码打印
  PURIKURA = 8, // 大头贴加印
  IDENTITY = 9, // 证件照
  PORTRAIT = 10, // 肖像照
}

export enum OrderImageTypeEnum {
  COMPOSE_PHOTO = "COMPOSE_PHOTO",
  SELECT_PHOTO = "SELECT_PHOTO",
  ORIGIN_PHOTO = "ORIGIN_PHOTO",
}

export enum OrderTypeEnum {
  PURIKURA = 1, // 大头贴
  SINGLE_PHOTO = 2, // 单图写真
  MULTIPLE_PHOTO = 3, // 组图写真
  ID_PHOTO = 4, //证件照
  PORTRAIT = 5, //肖像照
}

export const belongToAIOrderArray = [
  OrderTypeEnum.SINGLE_PHOTO,
  OrderTypeEnum.MULTIPLE_PHOTO,
];

export enum ImageTypeEnum {
  PUZZLE_PHOTO = 1, //拼图
  UNDERLAY_PHOTO = 2, //底片
}

export enum SalesTypeEnum {
  PREVIOUS_ORDER = 1, //前期订单
  ONE_SALES = 2, //一销
  AGAIN_SALES = 3, //二销
}

// 支付单类型
export enum PayTypeEnum {
  PAYMENT = 1,
  REFUND = 2,
}

// 收款支付状态
export enum PaymentTypeEnum {
  UN_PAY = 1, //未支付
  TRADE_SUCCESS = 2, //已支付
  REFUND_SUCCESS = 3, //已退款
  TO_CLOSE = 4, //待关闭
}

// 退款支付状态
export enum RefundTypeEnum {
  WAIT_REFUND = 1, //待打款
  REFUND_SUCCESS = 2, //已打款
}

//退款方式
export enum RefundWayEnum {
  ORIGINAL_RETURN = 1, //原路返回
  BANK_CARD_TRANSFER = 2, //银行卡转账
  OTHER_RETURN = 3, //其他退款
}

//支付方式
export enum PayWayEnum {
  WXPAY = 1, //微信
  ALIPAY = 2, //支付宝
  TIKTOK = 3, //抖音
  MEITUAN = 4, //抖音
}

export enum UploadTypeEnum {
  PHOTO = 1,
  CAMERA = 2,
  DIGITAL = 3,
}

export const PHOTO_STATUS_OPTIONS = [
  {
    label: "等待制作",
    value: BusinessStatusEnum.WAIT_COMPOSE,
  },
  {
    label: "等待选片",
    value: BusinessStatusEnum.WAIT_SELECT,
  },
  {
    label: "选片已完成",
    value: BusinessStatusEnum.FINISH_SELECT,
  },
];

export const ORDER_STATUS_OPTIONS = [
  {
    label: "待付款",
    value: OrderStatusEnum.WAIT_PAY,
  },
  {
    label: "服务中",
    value: OrderStatusEnum.IN_PROGRESS,
  },
  {
    label: "已完成",
    value: OrderStatusEnum.DONE,
  },
  {
    label: "已取消",
    value: OrderStatusEnum.CANCEL,
  },
  {
    label: "已退款",
    value: OrderStatusEnum.REFUND,
  },
];

export const SALES_TYPE_OPTIONS = [
  { label: "前期", value: SalesTypeEnum.PREVIOUS_ORDER },
  { label: "一销", value: SalesTypeEnum.ONE_SALES },
  { label: "二销", value: SalesTypeEnum.AGAIN_SALES },
];

export const PAY_TYPE_OPTIONS = [
  {
    label: "收款",
    value: PayTypeEnum.PAYMENT,
  },
  {
    label: "退款",
    value: PayTypeEnum.REFUND,
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: "未支付",
    value: PaymentTypeEnum.UN_PAY,
  },
  {
    label: "已支付",
    value: PaymentTypeEnum.TRADE_SUCCESS,
  },
  {
    label: "已退款",
    value: PaymentTypeEnum.REFUND_SUCCESS,
  },
  {
    label: "待关闭",
    value: PaymentTypeEnum.TO_CLOSE,
  },
];

export const REFUND_TYPE_OPTIONS = [
  {
    label: "待打款",
    value: RefundTypeEnum.WAIT_REFUND,
  },
  {
    label: "已打款",
    value: RefundTypeEnum.REFUND_SUCCESS,
  },
];

export const REFUND_WAY_OPTIONS = [
  {
    label: "原路返回",
    value: RefundWayEnum.ORIGINAL_RETURN,
    channel: PayWayEnum.WXPAY, // 退款方式对应渠道暂时写死
  },
  {
    label: "银行卡转账",
    value: RefundWayEnum.BANK_CARD_TRANSFER,
  },
  {
    label: "其他退款",
    value: RefundWayEnum.OTHER_RETURN,
    channel: PayWayEnum.TIKTOK,
  },
];

export const PAY_WAY_OPTIONS = [
  {
    label: "微信",
    value: PayWayEnum.WXPAY,
  },
  {
    label: "支付宝",
    value: PayWayEnum.ALIPAY,
  },
  {
    label: "抖音券",
    value: PayWayEnum.TIKTOK,
  },
  {
    label: "美团券",
    value: PayWayEnum.MEITUAN,
  },
];

export const UPLOAD_TYPE_OPTIONS = [
  {
    label: "手机上传",
    value: UploadTypeEnum.PHOTO,
  },
  {
    label: "拍照机上传",
    value: UploadTypeEnum.CAMERA,
  },
  {
    label: "选择数字分身",
    value: UploadTypeEnum.DIGITAL,
  },
];

/** 冲印订单业务状态码集合 */
export const PRINT_ORDER_STATUS_CODES = [
  BusinessStatusEnum.UNPAID,
  BusinessStatusEnum.WAIT_PRINT,
  BusinessStatusEnum.FINISH,
];
/** 冲印订单成交渠道状态码集合 */
export const PRINT_ORDER_TRANSACTION_CHANNEL_CODES = [
  TransactionChannelEnum.SCAN_PRINT,
  TransactionChannelEnum.PURIKURA,
  TransactionChannelEnum.IDENTITY,
  TransactionChannelEnum.PORTRAIT,
];
/** 智拍订单业务状态码 */
export const BUSINESS_STATUS_CODES = [
  BusinessStatusEnum.UNPAID,
  BusinessStatusEnum.WAIT_PHOTO_CAPTURE,
  BusinessStatusEnum.WAIT_PHOTO_RETOUCH,
  BusinessStatusEnum.WAIT_FILL_INFO,
  BusinessStatusEnum.WAIT_SELECT_TEMPLATE,
  BusinessStatusEnum.WAIT_UPLOAD_PHOTO,
  BusinessStatusEnum.MAKING,
  BusinessStatusEnum.WAIT_SELECT,
  BusinessStatusEnum.WAIT_PRINT,
  BusinessStatusEnum.FINISH,
];
/** 智拍订单成交渠道状态码 */
export const TRANSACTION_CHANNEL_CODES = [
  TransactionChannelEnum.SHOOT_DEVICE,
  TransactionChannelEnum.MINI_PROGRAM,
  TransactionChannelEnum.MARKETING_ACTIVITY,
  TransactionChannelEnum.MEMBER_EQUITY,
  TransactionChannelEnum.TIK_TOK,
  TransactionChannelEnum.KEE_TA,
];
